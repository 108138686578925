import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { AmplifyAuthenticator } from '@aws-amplify/ui-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@aws-amplify/ui/dist/style.css'
import Header from './components/Header'
import { routes } from './routes'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing:border-box;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`

const App: React.FC = () => (
  <AmplifyAuthenticator>
    <>
      <GlobalStyle />
      <Router>
        <>
          <Header />
          <Switch>
            {routes.map((route) => (
              <Route key={route.path} exact path={route.path} component={route.component} />
            ))}
          </Switch>
        </>
      </Router>
    </>
  </AmplifyAuthenticator>
)

export default App
