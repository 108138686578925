/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid'
import { Slider } from '@rio-cloud/rio-uikit'
import { DymState } from '../API'
import { NewBooking } from '../types'
import useBookings from '../lib/useBookings'

const statesOptions = [
  {
    key: DymState.EXPECTED,
    value: DymState.EXPECTED,
    text: DymState.EXPECTED,
  },
  {
    key: DymState.ARRIVED,
    value: DymState.ARRIVED,
    text: DymState.ARRIVED,
  },
  {
    key: DymState.IN_PROCESS,
    value: DymState.IN_PROCESS,
    text: DymState.IN_PROCESS,
  },
  {
    key: DymState.SAP_BOOKED,
    value: DymState.SAP_BOOKED,
    text: DymState.SAP_BOOKED,
  },
  {
    key: DymState.BOOKED,
    value: DymState.BOOKED,
    text: DymState.BOOKED,
  },
  {
    key: DymState.WITH_PARKING,
    value: DymState.WITH_PARKING,
    text: DymState.WITH_PARKING,
  },
]

const tanspFamNames = [
  'Alutank E&S',
  'Batteriekasten ex HAP ',
  'Batteriekasten Mevis',
  'Behr Heizung & Filtergehäuse',
  'Behr Kühler',
  'Beinbauer Tragbock',
  'Beinbauer_exLog',
  'Beinbauer_M5',
  'Bodenmatten 1',
  'Dachau Achse A6',
  'Dachaul Achse A7',
  'Diebald Einstiegskotfluegelset',
  'Diebald Stossfaengerset',
  'exLog 3007 Stirnwandverkleidung',
  'exLog 6',
  'Exlog1',
  'Fensterhebermodul Brose Hallstadt',
  'FHS Kabelstränge Kroschu NHMR',
  'Fritzmeier Hochdach',
  'Getriebe Konzern',
  'Gillhuber',
  'Gustavsburg Rahmenset',
  'Heizung/Klima NHMR Valeo',
  'I-Tafel',
  'Innenkotflügel',
  'JIS Tankträger / FUP HAG',
  'JIT Karobau Rückwand Sitzqt',
  'JIT Karobau Seitenwand',
  'JIT Windlauf / Türe',
  'Kienbacher Abdeckung Wischeranlage',
  'Kilppan Mittelmodule',
  'Klippan Schlafliege',
  'Klippan,Staukasten NHMR',
  'Kombibock AD-Blue Halter Konsole &',
  'KU Lack FHS Steyr',
  'KU-Lack Stossfänger Steyr',
  'Kühlergitter',
  'Kühlwasserrohre',
  'KUL Steyr Aeropaket/Spoiler',
  'LOGWIN Sitze Mix ISGRRE',
  'Matten_Dachhimmel_Quervorhänge_',
  'Motoren Nürnberg',
  'Recaro Sitze',
  'Reifen/Räder LKW, Dachau',
  'Resequenzierung',
  'RTA Abgasschalldämpfer',
  'Sattelkupplungen SAF Holland',
  'Sauermann Kotfluegel',
  'Spicer Gelenkwellen',
  'Spiegel Mekra',
  'Systemträgerblech',
  'TIV NHMR SMP',
  'Unterfahrschutz JIT/JIS',
  'VA Achse Salzgitter',
  'ZF Friedrichshafen Getriebe',
  'ZSB FHS Rohbauteile aus Steyr ',
  'ZSB Hinterachsbrücken neu',
]

const transpCompanyNames = [
  '### \\####\\,',
  'allgaier GmbH,',
  'Alutank E&S',
  'Batteriekasten ex HAP ',
  'Batteriekasten Mevis',
  'Behr Heizung & Filtergehäuse',
  'Behr Kühler',
  'Beinbauer Tragbock',
  'Beinbauer_exLog',
  'Beinbauer_M5',
  'Bodenmatten 1',
  'Dachau Achse A6',
  'Dachaul Achse A7',
  'Dettendorfer,',
  'Diebald Einstiegskotfluegelset',
  'Diebald Stossfaengerset',
  'Duvenbeck,',
  'EUROCAM-Transport & Logistics,,',
  'exLog 3007 Stirnwandverkleidung',
  'exLog 6',
  'Exlog1',
  'Fensterhebermodul Brose Hallstadt',
  'FHS Kabelstränge Kroschu NHMR',
  'Fritzmeier Hochdach',
  'Gerex II , sp.z.o.o, sp. Kom.,',
  'Getriebe Konzern',
  'Gillhuber',
  'Gillhuber,',
  'Gress,',
  'Gustavsburg Rahmenset',
  'Heizung/Klima NHMR Valeo',
  'Hugger,',
  'I-Tafel',
  'Innenkotflügel',
  'International Alexander GmbH,',
  'J.S. Logistics KFT,',
  'JIS Tankträger / FUP HAG',
  'JIT Karobau Rückwand Sitzqt',
  'JIT Karobau Seitenwand',
  'JIT Windlauf / Türe',
  'Johann Dettendorfer Spedition,',
  'Kienbacher Abdeckung Wischeranlage',
  'Kilppan Mittelmodule',
  'Klippan Schlafliege',
  'Klippan,Staukasten NHMR',
  'KLOIBER,',
  'Kombibock AD-Blue Halter Konsole &',
  'KU Lack FHS Steyr',
  'KU-Lack Stossfänger Steyr',
  'Kühlergitter',
  'Kühlwasserrohre',
  'KUL Steyr Aeropaket/Spoiler',
  'LKW WALTER Internationale,',
  'LOGWIN Sitze Mix ISGRRE',
  'LOGWIN,',
  'Matten_Dachhimmel_Quervorhänge_',
  'Motoren Nürnberg',
  'Neuberger,',
  'Recaro Sitze',
  'Reichhart Logistik GmbH,',
  'Reifen/Räder LKW, Dachau',
  'Resequenzierung',
  'Rovologistik CZ Group, SE,',
  'RTA Abgasschalldämpfer',
  'Rudolph Automotive Logistik GmbH,',
  'Sattelkupplungen SAF Holland',
  'Sauermann Kotfluegel',
  'Scherm Logline Transport GmbH,',
  'Scherm,',
  'Spicer Gelenkwellen',
  'Spiegel Mekra',
  'Systemträgerblech',
  'test,',
  'TIV NHMR SMP',
  'Transimeksa UAB,',
  'UAB Transimeksa,',
  'Unterfahrschutz JIT/JIS',
  'VA Achse Salzgitter',
  'ZF Friedrichshafen Getriebe',
  'ZSB FHS Rohbauteile aus Steyr ',
  'ZSB Hinterachsbrücken neu',
]

const TestScreen: React.FunctionComponent = () => {
  const momentNow = moment()
  const [formState, setFormState] = useState({
    date: momentNow.format('YYYY-MM-DD'),
    time: momentNow.format('HH:mm'),
    status: DymState.EXPECTED,
    license_plate: '',
    count: 5,
  })

  const { bookingsByArrivalDate, createBooking, deleteBooking } = useBookings()
  const [isDeleteDisabled, setDeleteDisabled] = useState(bookingsByArrivalDate.length === 0)
  const [isProd, setIsProd] = useState(true)

  const deleteAll = () => {
    if (bookingsByArrivalDate.length > 0) {
      bookingsByArrivalDate.map((b) => deleteBooking(b.id))
    }
  }

  useEffect(() => {
    setIsProd(window.location.href.split('.')[0] === 'https://www')
  }, [])

  useEffect(() => {
    setDeleteDisabled(bookingsByArrivalDate.length === 0)
  }, [bookingsByArrivalDate.length])

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < formState.count; i++) {
      const newBooking: NewBooking = {
        arrival_date_key: moment(formState.date, 'YYYY-MM-DD').format('YYYY/MM/DD'),
        transport_family: Math.floor(Math.random() * 11000) + 1,
        transport_family_name: tanspFamNames[Math.floor(Math.random() * tanspFamNames.length)],
        transport_company:
          transpCompanyNames[Math.floor(Math.random() * transpCompanyNames.length)],
        trailer_id: `${Math.floor(Math.random() * 90000) + 10000}`,
        license_plate:
          formState.license_plate.replace(/[^0-9a-z]/gi, '').toUpperCase() === ''
            ? uuidv4().substring(0, 7).toUpperCase()
            : formState.license_plate,
        planned_date_of_arrival: moment(
          `${formState.date} ${formState.time}`,
          'YYYY-MM-DD HH:mm',
        ).format(),
        dym_timestamp_arrived:
          formState.status === DymState.ARRIVED
            ? moment(`${formState.date} ${formState.time}`, 'YYYY-MM-DD HH:mm')
                .add(15, 'minutes')
                .format()
            : undefined,
        dym_status: formState.status,
      }
      if (!newBooking.planned_date_of_arrival) {
        newBooking.planned_date_of_arrival = moment().format()
      }

      createBooking(newBooking)
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const newFormState = { ...formState, [name]: value }
    setFormState(newFormState)
  }

  const onChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target
    const newFormState = { ...formState, [name]: value }
    setFormState(newFormState)
  }

  return (
    <div className="container-fluid margin-top-25">
      <div className="row">
        <div className="col-xs-12 col-md-8 col-lg-4 col-xl-4 col-xl-offset-4 col-md-offset-2 col-lg-offset-4">
          {!isProd && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={deleteAll}
              disabled={isDeleteDisabled}
            >
              Delete all bookings
            </button>
          )}
          <hr />
          <h3>Create fake bookings</h3>
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label className="control-label" htmlFor="date">
                Expected Date
              </label>
              <input
                className="form-control"
                id="date"
                type="date"
                name="date"
                placeholder="dd/mm/yy"
                value={formState.date}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="time">
                Expected Time
              </label>
              <input
                className="form-control"
                type="time"
                name="time"
                id="time"
                placeholder="hh:mm"
                value={formState.time}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="status">
                Status
              </label>
              <select
                className="form-control"
                name="status"
                id="status"
                value={formState.status}
                onChange={onChangeSelect}
              >
                {statesOptions.map((option) => (
                  <option value={option.value} key={option.key}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="license_plate">
                License Plate
              </label>
              <input
                className="form-control"
                name="license_plate"
                id="license_plate"
                placeholder="random if empty"
                value={formState.license_plate}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="count">
                How many: {formState.count}
              </label>
              <Slider
                minValue={1}
                maxValue={50}
                step={1}
                value={formState.count}
                onChange={(value: number) => {
                  setFormState((prevState) => ({ ...prevState, count: value }))
                }}
              />
            </div>

            <button className="btn btn-primary" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default TestScreen
