import {
  useQuery,
  gql,
  useMutation,
  NetworkStatus,
  useSubscription,
  StoreObject,
} from '@apollo/client'
import moment from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import {
  CreateBookingMutation,
  CreateBookingMutationVariables,
  DeleteBookingMutation,
  DeleteBookingMutationVariables,
  DymState,
  ListBookingsQuery,
  ListBookingsQueryVariables,
  BookingsByArrivalDateQuery,
  BookingsByArrivalDateQueryVariables,
  UpdateBookingMutation,
  UpdateBookingMutationVariables,
  UpdateBookingInput,
  OnCreateBookingSubscription,
  OnUpdateBookingSubscription,
  PushBookingToSapMutation,
  PushBookingToSapMutationVariables,
  PushSapBookingInput,
} from '../API'
import { Booking, NewBooking } from '../types'
import {
  deleteBooking as DeleteBooking,
  createBooking as CreateBooking,
  updateBooking as UpdateBooking,
  pushBookingToSap as PushBookingToSap,
} from '../graphql/mutations'
import {
  listBookings as ListBookings,
  bookingsByArrivalDate as BookingsByArrivalDate,
} from '../graphql/queries'
import {
  onCreateBooking as OnCreateBooking,
  onUpdateBooking as OnUpdateBooking,
} from '../graphql/subscriptions'
import { filterNull } from '../utils/mapping'

type BookingsCollection = NonNullable<ListBookingsQuery['listBookings']>['items']
const getBookingsFromResponse = (items?: BookingsCollection): Booking[] =>
  items?.filter(filterNull) ?? []

type UseBookingsInterface = {
  bookings: Booking[]
  bookingsByArrivalDate: Booking[]
  createBooking: (newBooking: NewBooking) => void
  deleteBooking: (id: string) => void
  updateBooking: (bookingInput: UpdateBookingInput) => void
  pushBookingToSap: (pushSapBookingInputData: PushSapBookingInput) => void
  arrivalDate: string
  setArrivalDate: (arrivalDate: string | moment.Moment) => void
  networkStatus: NetworkStatus
  subscribeToMoreBookingsByDate: () => () => void
}

type ExistingBookingsRefs = { items: { __ref: string }[]; nextToken: null | string }

const useBookings = (): UseBookingsInterface => {
  const [arrivalDate, setArrivalDate] = useState<string>(moment().format('YYYY/MM/DD'))
  const { data: bookingsResponse } = useQuery<ListBookingsQuery, ListBookingsQueryVariables>(
    gql(ListBookings),
  )

  const bookingsQuery = useQuery<BookingsByArrivalDateQuery, BookingsByArrivalDateQueryVariables>(
    gql(BookingsByArrivalDate),
    { variables: { arrival_date_key: arrivalDate } },
  )
  const {
    data: bookingsByArrivalDateResponse,
    networkStatus,
    // subscribeToMore: subscribeToMoreBookingsByDateInternal,
  } = bookingsQuery

  const subscribeToMoreBookingsByDateInternal = bookingsQuery?.subscribeToMore

  const bookings = getBookingsFromResponse(bookingsResponse?.listBookings?.items)
  const bookingsByArrivalDate = useMemo(
    () => getBookingsFromResponse(bookingsByArrivalDateResponse?.bookingsByArrivalDate?.items),
    [bookingsByArrivalDateResponse?.bookingsByArrivalDate?.items],
  )
  const [deleteBooking] = useMutation<DeleteBookingMutation, DeleteBookingMutationVariables>(
    gql(DeleteBooking),
    {
      variables: {
        input: { id: '' },
      },
      update(cache, { data }) {
        const id = data?.deleteBooking?.id
        if (!id) return
        cache.modify({
          fields: {
            listBookings(existingBookingRefs: ExistingBookingsRefs, { readField }) {
              const updatedRefs = existingBookingRefs.items.filter(
                (bookingRef) => id !== readField('id', bookingRef),
              )
              return { ...existingBookingRefs, items: updatedRefs }
            },
          },
        })
        cache.evict({ id: cache.identify(data?.deleteBooking as StoreObject) })
      },
    },
  )

  const [pushBookingToSap] = useMutation<
    PushBookingToSapMutation,
    PushBookingToSapMutationVariables
  >(
    gql(PushBookingToSap),
    // {
    // variables: {
    //   pushSapBookingInputData: {
    //     id: '',
    //     dym_status: DymState.SAP_BOOKED,
    //     dym_timestamp_sap_booked: '',
    //   },
    // },
    // update(cache, { data }) {
    //   cache.writeFragment({
    //     id: cache.identify(data?.pushBookingToSAP as StoreObject),
    //     data: {
    //       ...data?.pushBookingToSAP,
    //     },
    //     fragment: gql`
    //       fragment ModifyBooking on Booking {
    //         id
    //         arrival_date_key
    //         transport_family
    //         trailer_id
    //         registration_number
    //         difference
    //         planned_date_of_arrival
    //         comment
    //         status
    //         last_modified
    //         ba_call_first_pearl
    //         ba_needed_first_pearl
    //         special_transporttrailer
    //         trailer_calculation
    //         license_plate
    //         dym_status
    //         dym_timestamp_expected
    //         dym_timestamp_arrived
    //         dym_timestamp_in_process
    //         dym_timestamp_sap_booked
    //         dym_timestamp_booked
    //         dym_timestamp_with_parking
    //         createdAt
    //         updatedAt
    //       }
    //     `,
    //   })
    // },
    // }
  )

  const [updateBooking] = useMutation<UpdateBookingMutation, UpdateBookingMutationVariables>(
    gql(UpdateBooking),
    {
      variables: { input: { id: '' } },
      update(cache, { data }) {
        cache.writeFragment({
          id: cache.identify(data?.updateBooking as StoreObject),
          data: {
            ...data?.updateBooking,
          },
          fragment: gql`
            fragment ModifyBooking on Booking {
              id
              arrival_date_key
              transport_family
              trailer_id
              registration_number
              difference
              planned_date_of_arrival
              comment
              status
              last_modified
              ba_call_first_pearl
              ba_needed_first_pearl
              special_transporttrailer
              trailer_calculation
              license_plate
              dym_status
              dym_timestamp_expected
              dym_timestamp_arrived
              dym_timestamp_in_process
              dym_timestamp_sap_booked
              dym_timestamp_booked
              dym_timestamp_with_parking
              createdAt
              updatedAt
            }
          `,
        })
      },
    },
  )

  useSubscription<OnUpdateBookingSubscription>(gql(OnUpdateBooking), {
    onSubscriptionData({ subscriptionData, client: { cache } }) {
      const updatedBooking = subscriptionData.data?.onUpdateBooking
      if (!updatedBooking) return
      cache.writeFragment({
        id: cache.identify(updatedBooking as StoreObject),
        fragment: gql`
          fragment UpdatedBooking on Booking {
            id
            arrival_date_key
            transport_family
            trailer_id
            registration_number
            difference
            planned_date_of_arrival
            comment
            status
            last_modified
            ba_call_first_pearl
            ba_needed_first_pearl
            special_transporttrailer
            trailer_calculation
            license_plate
            dym_status
            dym_timestamp_expected
            dym_timestamp_arrived
            dym_timestamp_in_process
            dym_timestamp_sap_booked
            dym_timestamp_booked
            dym_timestamp_with_parking
            createdAt
            updatedAt
          }
        `,
        data: updatedBooking,
      })
    },
  })

  const [createBooking] = useMutation<CreateBookingMutation, CreateBookingMutationVariables>(
    gql(CreateBooking),
    {
      variables: {
        input: {
          arrival_date_key: moment().format('YYYY/MM/DD'),
          trailer_id: `${Math.floor(Math.random() * 90000) + 10000}`,
          dym_status: DymState.EXPECTED,
        },
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            listBookings(existingBookingRefs: ExistingBookingsRefs, { readField }) {
              const bookingData = data?.createBooking
              if (!bookingData) return existingBookingRefs
              const newBookingRef = cache.writeFragment({
                data: bookingData,
                fragment: gql`
                  fragment NewBooking on Booking {
                    id
                    arrival_date_key
                    transport_family
                    trailer_id
                    registration_number
                    difference
                    planned_date_of_arrival
                    comment
                    status
                    last_modified
                    ba_call_first_pearl
                    ba_needed_first_pearl
                    special_transporttrailer
                    trailer_calculation
                    license_plate
                    dym_status
                    dym_timestamp_expected
                    dym_timestamp_arrived
                    dym_timestamp_in_process
                    dym_timestamp_sap_booked
                    dym_timestamp_booked
                    dym_timestamp_with_parking
                    createdAt
                    updatedAt
                  }
                `,
              })
              // Quick safety check - if the new comment is already
              // present in the cache, we don't need to add it again.
              if (
                existingBookingRefs.items.some((ref) => readField('id', ref) === bookingData.id)
              ) {
                return existingBookingRefs
              }

              return {
                ...existingBookingRefs,
                items: [...(existingBookingRefs?.items ?? []), newBookingRef],
              }
            },
          },
        })
      },
    },
  )

  const subscribeToMoreBookingsByDate = useCallback(() => {
    if (subscribeToMoreBookingsByDateInternal) {
      return subscribeToMoreBookingsByDateInternal<OnCreateBookingSubscription>({
        document: gql(OnCreateBooking),
        variables: {},
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          const newBooking = subscriptionData.data.onCreateBooking
          const newData: BookingsByArrivalDateQuery = {
            bookingsByArrivalDate: {
              __typename: 'ModelBookingConnection',
              nextToken: prev.bookingsByArrivalDate?.nextToken ?? null,
              items: prev.bookingsByArrivalDate?.items?.concat(newBooking || null) ?? [],
            },
          }
          return newData
        },
      })
    }
    return () => undefined
  }, [subscribeToMoreBookingsByDateInternal])

  const setArrivalDateCb = useCallback(
    (newArrivalDate: string | moment.Moment) =>
      setArrivalDate(moment(newArrivalDate).format('YYYY/MM/DD')),
    [setArrivalDate],
  )
  const createBookingCb = useCallback(
    (newBooking: NewBooking) => createBooking({ variables: { input: newBooking } }),
    [createBooking],
  )
  const updateBookingCb = useCallback(
    (bookingInput) => updateBooking({ variables: { input: bookingInput } }),
    [updateBooking],
  )

  const pushBookingToSapCb = useCallback(
    (pushSapBookingInputData) => pushBookingToSap({ variables: { pushSapBookingInputData } }),
    [pushBookingToSap],
  )

  const deleteBookingCb = useCallback(
    (id?: string | null) => {
      if (id?.length) deleteBooking({ variables: { input: { id } } })
    },
    [deleteBooking],
  )
  return {
    bookings,
    bookingsByArrivalDate,
    networkStatus,
    arrivalDate,
    setArrivalDate: setArrivalDateCb,
    createBooking: createBookingCb,
    updateBooking: updateBookingCb,
    pushBookingToSap: pushBookingToSapCb,
    deleteBooking: deleteBookingCb,
    subscribeToMoreBookingsByDate,
  }
}

export default useBookings
