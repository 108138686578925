/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      arrival_date_key
      transport_family
      transport_family_name
      transport_company
      trailer_id
      registration_number
      difference
      planned_date_of_arrival
      comment
      status
      last_modified
      ba_call_first_pearl
      ba_needed_first_pearl
      special_transporttrailer
      trailer_calculation
      license_plate
      dym_status
      dym_timestamp_expected
      dym_timestamp_arrived
      dym_timestamp_in_process
      dym_timestamp_sap_booked
      dym_timestamp_booked
      dym_timestamp_with_parking
      createdAt
      updatedAt
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      arrival_date_key
      transport_family
      transport_family_name
      transport_company
      trailer_id
      registration_number
      difference
      planned_date_of_arrival
      comment
      status
      last_modified
      ba_call_first_pearl
      ba_needed_first_pearl
      special_transporttrailer
      trailer_calculation
      license_plate
      dym_status
      dym_timestamp_expected
      dym_timestamp_arrived
      dym_timestamp_in_process
      dym_timestamp_sap_booked
      dym_timestamp_booked
      dym_timestamp_with_parking
      createdAt
      updatedAt
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      arrival_date_key
      transport_family
      transport_family_name
      transport_company
      trailer_id
      registration_number
      difference
      planned_date_of_arrival
      comment
      status
      last_modified
      ba_call_first_pearl
      ba_needed_first_pearl
      special_transporttrailer
      trailer_calculation
      license_plate
      dym_status
      dym_timestamp_expected
      dym_timestamp_arrived
      dym_timestamp_in_process
      dym_timestamp_sap_booked
      dym_timestamp_booked
      dym_timestamp_with_parking
      createdAt
      updatedAt
    }
  }
`;
export const pushBookingToSap = /* GraphQL */ `
  mutation PushBookingToSap($pushSapBookingInputData: PushSapBookingInput) {
    pushBookingToSAP(pushSapBookingInputData: $pushSapBookingInputData) {
      success
      errrors
      data {
        id
        arrival_date_key
        trailer_id
        dym_status
        dym_timestamp_sap_booked
      }
    }
  }
`;
export const testPushBookingToSap = /* GraphQL */ `
  mutation TestPushBookingToSap($pushSapBookingInputData: String) {
    testPushBookingToSAP(pushSapBookingInputData: $pushSapBookingInputData) {
      success
      errrors
      data {
        id
        arrival_date_key
        trailer_id
        dym_status
        dym_timestamp_sap_booked
      }
    }
  }
`;
