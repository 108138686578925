/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      arrival_date_key
      transport_family
      transport_family_name
      transport_company
      trailer_id
      registration_number
      difference
      planned_date_of_arrival
      comment
      status
      last_modified
      ba_call_first_pearl
      ba_needed_first_pearl
      special_transporttrailer
      trailer_calculation
      license_plate
      dym_status
      dym_timestamp_expected
      dym_timestamp_arrived
      dym_timestamp_in_process
      dym_timestamp_sap_booked
      dym_timestamp_booked
      dym_timestamp_with_parking
      createdAt
      updatedAt
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        arrival_date_key
        transport_family
        transport_family_name
        transport_company
        trailer_id
        registration_number
        difference
        planned_date_of_arrival
        comment
        status
        last_modified
        ba_call_first_pearl
        ba_needed_first_pearl
        special_transporttrailer
        trailer_calculation
        license_plate
        dym_status
        dym_timestamp_expected
        dym_timestamp_arrived
        dym_timestamp_in_process
        dym_timestamp_sap_booked
        dym_timestamp_booked
        dym_timestamp_with_parking
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingsByArrivalDate = /* GraphQL */ `
  query BookingsByArrivalDate(
    $arrival_date_key: String
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByArrivalDate(
      arrival_date_key: $arrival_date_key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        arrival_date_key
        transport_family
        transport_family_name
        transport_company
        trailer_id
        registration_number
        difference
        planned_date_of_arrival
        comment
        status
        last_modified
        ba_call_first_pearl
        ba_needed_first_pearl
        special_transporttrailer
        trailer_calculation
        license_plate
        dym_status
        dym_timestamp_expected
        dym_timestamp_arrived
        dym_timestamp_in_process
        dym_timestamp_sap_booked
        dym_timestamp_booked
        dym_timestamp_with_parking
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingsByTrailerId = /* GraphQL */ `
  query BookingsByTrailerId(
    $trailer_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByTrailerID(
      trailer_id: $trailer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        arrival_date_key
        transport_family
        transport_family_name
        transport_company
        trailer_id
        registration_number
        difference
        planned_date_of_arrival
        comment
        status
        last_modified
        ba_call_first_pearl
        ba_needed_first_pearl
        special_transporttrailer
        trailer_calculation
        license_plate
        dym_status
        dym_timestamp_expected
        dym_timestamp_arrived
        dym_timestamp_in_process
        dym_timestamp_sap_booked
        dym_timestamp_booked
        dym_timestamp_with_parking
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
