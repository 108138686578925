import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import styled, { css } from 'styled-components'
import { config, animated, useTransition } from 'react-spring'
import { Booking } from '../types'
import { DymState, UpdateBookingInput } from '../API'

type Props = {
  booking: Booking
  updateBooking: (bookingInput: UpdateBookingInput) => void
}

const Button = styled.button<{ isActive: boolean; arrived: boolean }>`
  padding: 0.6em;
  color: black;
  background-color: white;
  margin: 0.5em 1em;

  ${(p) =>
    p.isActive &&
    css`
      color: white;
      background-color: #59b755;
    `}

  ${(p) =>
    p.isActive &&
    p.arrived &&
    css`
      color: white;
      background-color: #ff6347;
    `}
`

const Title = styled.h4`
  margin: 0;
  font-size: 1em;
`

const FlexRow = styled.div`
  display: flex;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1em;
`

const StyledIcon = styled.div<{ isActive: boolean; arrived: boolean }>`
  ${(p) =>
    !p.isActive && p.arrived
      ? css`
          color: green;
        `
      : css`
          color: white;
        `}
`

const SecurityBookingItem: React.FC<Props> = React.memo(({ booking, updateBooking }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id, dym_status, license_plate } = booking
  const [isActive, setIsActive] = useState(false)
  const [visible, setVisible] = useState(false)
  const [delayID, setDelayID] = useState<NodeJS.Timeout | null>(null)
  const transitions = useTransition(visible, null, {
    from: { opacity: 0, scale: 0.8 },
    enter: { scale: 1, opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  })

  useEffect(() => {
    setVisible(true)
  }, [])

  const isStateArrived = dym_status === DymState.ARRIVED
  const isStateExpected = dym_status === DymState.EXPECTED
  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    if (delayID) {
      clearTimeout(delayID)
      setDelayID(null)
      setVisible(true)
      setIsActive(false)
    } else {
      const timeoutID = setTimeout(() => {
        setVisible(false)
        const newBooking: UpdateBookingInput = {
          id,
        }
        if (isStateExpected) {
          newBooking.dym_status = DymState.ARRIVED
          newBooking.dym_timestamp_arrived = moment().format()
        }
        if (isStateArrived) {
          newBooking.dym_status = DymState.EXPECTED
        }
        updateBooking(newBooking)
      }, 2000)
      setDelayID(timeoutID)
      setIsActive(true)
    }
  }

  useEffect(
    () => () => {
      if (delayID) {
        clearTimeout(delayID)
      }
    },
    [delayID],
  )

  return (
    <div>
      {transitions.map(({ item, key, props }) =>
        item ? (
          <animated.div key={key} style={props}>
            <Button
              className="panel panel-default col-md-2 col-xl-2"
              type="button"
              onClick={onClick}
              isActive={isActive}
              arrived={isStateArrived}
            >
              <div className="panel-body">
                <FlexRow className="row">
                  <div className="col-md-6">
                    <Title>{license_plate}</Title>
                  </div>
                  <IconContainer className="col-md-2">
                    <StyledIcon
                      className={`rioglyph rioglyph-${isStateArrived ? 'revert' : 'ok'}`}
                      isActive={isActive}
                      arrived={isStateArrived}
                    />
                  </IconContainer>
                </FlexRow>
              </div>
            </Button>
          </animated.div>
        ) : null,
      )}
    </div>
  )
})

export default SecurityBookingItem
