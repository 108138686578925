import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client'
import Auth from '@aws-amplify/auth'
import { AuthOptions, AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import appSyncConfig from '../aws-exports'

const config: {
  url: string
  region: string
  auth: AuthOptions
} = {
  url: appSyncConfig.aws_appsync_graphqlEndpoint,
  region: appSyncConfig.aws_appsync_region,
  auth: {
    type: appSyncConfig.aws_appsync_authenticationType as AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
}

export const client = new ApolloClient({
  link: ApolloLink.from([createAuthLink(config), createSubscriptionHandshakeLink(config)]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
})
