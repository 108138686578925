/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:d1e36ed1-42ce-4dd7-b192-68254fa41e06",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_NXdWJV3Xp",
    "aws_user_pools_web_client_id": "4escr608b8pf7ical8qd12m4os",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://7wbmtgh4yngltkewmr7vz6hhsa.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-5wulmhzpbfhbpfy4ieprfzuxky",
    "aws_user_files_s3_bucket": "csvexportsprod-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
