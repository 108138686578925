import { AmplifySignOut } from '@aws-amplify/ui-react'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useOnClickOutside from 'use-onclickoutside'
import styled from 'styled-components'
import { DymState } from '../API'
import { useAuth } from '../context/authentication/authenticationHooks'
import useBookings from '../lib/useBookings'
import { AppRoute } from '../routes'
import Spacer from './Spacer'

const HeaderContainer = styled.div`
  height: 98px;
  min-height: 98px;
  padding: 0 49px;
  border-bottom: 1px solid #d7d7d7;
`

const SignoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-self: flex-end;
`

const BellIconContainer = styled.button`
  all: unset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #d7d7d7;
  border-radius: 100%;
  font-size: 2em;
`

const Badge = styled.span`
  color: white;
  background-color: red;
  position: absolute;
  top: -8px;
  right: -8px;
`

const PopoverBottom = styled.div`
  display: block;
  top: 48px;
  left: -17px;
  width: 180px;
`

const PopoverArrow = styled.div`
  margin-left: -59px !important;
`

const isReceptionistScreen = (pathname: string): boolean => pathname === AppRoute.Receptionist

const Header: React.FC = () => {
  const { user } = useAuth()
  const location = useLocation()
  const [showNotification, setShowNotification] = useState(false)
  const { bookingsByArrivalDate, subscribeToMoreBookingsByDate } = useBookings()
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setShowNotification(false))

  const unhandledBookings = bookingsByArrivalDate.filter(
    (booking) => booking.dym_status === DymState.ARRIVED,
  ).length

  useEffect(() => {
    let unsubscribe: () => void = () => {}
    if (isReceptionistScreen(location.pathname)) {
      unsubscribe = subscribeToMoreBookingsByDate?.()
    }

    return () => {
      unsubscribe()
    }
  }, [subscribeToMoreBookingsByDate, location.pathname])

  const receptionistScreen = isReceptionistScreen(location.pathname)
  const showAlertIcon = receptionistScreen && unhandledBookings > 0
  return (
    <HeaderContainer>
      <div
        className=""
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {receptionistScreen && (
          <div className="position-relative">
            <BellIconContainer
              onClick={
                () => (showAlertIcon && !showNotification ? setShowNotification(true) : undefined)
                // eslint-disable-next-line react/jsx-curly-newline
              }
            >
              <span className="rioglyph rioglyph-notification" />
              {showAlertIcon && <Badge className="badge">{unhandledBookings}</Badge>}
            </BellIconContainer>
            {showNotification && (
              <PopoverBottom
                id="popover-bottom"
                role="tooltip"
                className="popover bottom"
                ref={ref}
              >
                <PopoverArrow className="arrow" />
                <div className="popover-content">Neue Fahrzeuge sind bereit für die Buchung</div>
              </PopoverBottom>
            )}
          </div>
        )}
        {!receptionistScreen && <div />}
        <SignoutContainer>
          Hallo {user?.getUsername()} <Spacer size={24} axis="horizontal" />
          <AmplifySignOut />
        </SignoutContainer>
      </div>
    </HeaderContainer>
  )
}

export default Header
