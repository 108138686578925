/* eslint-disable no-void */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo, useEffect } from 'react'
import moment from 'moment-timezone'
import styled from 'styled-components'
import { Dialog } from '@rio-cloud/rio-uikit'
import {
  Column,
  useTable,
  useFilters,
  Cell,
  TableProps,
  MetaBase,
  TableHeaderProps,
  TableCellProps,
} from 'react-table'
import { DymState, UpdateBookingInput, PushSapBookingInput } from '../API'
import BookingsFilterBar from '../components/BookingsFilterBar'
import useBookings from '../lib/useBookings'
import { Booking } from '../types'

const StyledContainer = styled.div`
  margin-top: 30px;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  > input[type='checkbox'] {
    margin: 0;
  }
`

const statusOrder = Object.values(DymState)

const filterBookings = (bookings: Booking[]): Booking[] => {
  const updatedFilteredBookings = bookings
    .filter((b) => b.dym_status !== DymState.EXPECTED)
    .sort(
      (a, b) =>
        moment(a.dym_timestamp_arrived).valueOf() - moment(b.dym_timestamp_arrived).valueOf(),
    )
    .sort((a, b) => statusOrder.indexOf(a.dym_status) - statusOrder.indexOf(b.dym_status))
  return updatedFilteredBookings
}

const SuccessIconContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

const SuccessIcon = styled.span`
  padding: 10px;
  color: green;
  border: 1px solid green;
  border-radius: 100%;
`

const TimeDisplay = styled.span<{ isOnTime: boolean }>`
  font-weight: ${(p) => (p.isOnTime ? 'normal' : 'bold')};
  color: ${(p) => (p.isOnTime ? 'black' : '#CA3B33')};
`

const formatDifference = (difference: number): string => {
  if (difference === 0) return ''
  return difference > 0 ? `(+${difference} min)` : `(${difference} min)`
}

const formatArrivalData = (
  planned_date_of_arrival: string | null,
  dym_timestamp_arrived: string | null,
) => {
  let formattedArrival = 'no data'

  if (!dym_timestamp_arrived && planned_date_of_arrival) {
    return moment(planned_date_of_arrival).format('HH:mm')
  }

  if (planned_date_of_arrival && dym_timestamp_arrived) {
    const arrivalTime = moment(planned_date_of_arrival).format('HH:mm')
    const difference = -moment(planned_date_of_arrival).diff(dym_timestamp_arrived, 'minutes')
    formattedArrival = `${arrivalTime}${formatDifference(difference)}`
  }

  return formattedArrival
}

const Hidden = styled.div`
  display: none;
`

const columns: ColumnData[] = [
  {
    Header: 'Trailer ID',
    accessor: 'trailer_id', // accessor is the "key" in the data
  },
  {
    Header: 'Einfahrtszeit',
    id: 'arrival_time',
    accessor: (booking) =>
      booking.dym_timestamp_arrived ? moment(booking.dym_timestamp_arrived).format('HH:mm') : '',
    Cell: (cell: Cell<Booking>) => (
      <TimeDisplay
        isOnTime={isOnTime(
          cell.row.values.dym_timestamp_arrived,
          cell.row.values.planned_date_of_arrival,
        )}
      >
        {cell.value}
      </TimeDisplay>
    ),
  },
  {
    Header: 'Geplante Ankunft',
    id: 'planned_arrival',
    accessor: (booking) =>
      formatArrivalData(
        booking.planned_date_of_arrival || null,
        booking.dym_timestamp_arrived || null,
      ),
    Cell: (cell: Cell<Booking>) => (
      <TimeDisplay
        isOnTime={isOnTime(
          cell.row.values.planned_date_of_arrival,
          cell.row.values.dym_timestamp_arrived,
        )}
      >
        {cell.value}
      </TimeDisplay>
    ),
  },
  {
    Header: 'Transp.-fam ID',
    accessor: 'transport_family',
  },
  {
    Header: 'Transp.-fam',
    accessor: 'transport_family_name',
  },
  {
    Header: 'Trailer Kz.',
    accessor: 'license_plate',
    Cell: (cell) => (
      <ColoursizedStatus dymState={cell.row.values.dym_status}>{cell.value}</ColoursizedStatus>
    ),
  },
  {
    Header: 'Spedition',
    accessor: 'transport_company',
  },
  {
    Header: () => <Hidden hidden />,
    id: 'planned_date_of_arrival',
    accessor: 'planned_date_of_arrival',
    Cell: () => <Hidden hidden />,
  },
  {
    Header: () => <Hidden hidden />,
    id: 'dym_timestamp_arrived',
    accessor: 'dym_timestamp_arrived',
    Cell: () => <Hidden hidden />,
  },
  {
    Header: () => <Hidden hidden />,
    id: 'dym_status',
    accessor: 'dym_status',
    Cell: () => <Hidden hidden />,
  },
  {
    Header: () => <Hidden hidden />,
    id: 'id',
    accessor: 'id',
    Cell: () => <Hidden hidden />,
  },
]

const hiddenColumns = ['planned_date_of_arrival', 'dym_timestamp_arrived', 'dym_status', 'id']

const stateToColor: Record<DymState, string> = {
  EXPECTED: 'inherit',
  ARRIVED: '#F2BF43',
  IN_PROCESS: '#4085CA',
  SAP_BOOKED: '#4085CA',
  BOOKED: '#59B755',
  WITH_PARKING: '#6C7989',
}

const TableRow = styled.tr<{ dymState: DymState }>`
  border-left: 2px solid red;

  > td:first-of-type {
    box-shadow: inset 5px 0 0 0 ${(p) => stateToColor[p.dymState]};
  }
`

const TextAlignCenter = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
`

const ColoursizedStatus = styled.span<{ dymState: DymState }>`
  color: ${(p) => stateToColor[p.dymState]};
  font-weight: bold;
`
const HelpText = styled.p`
  font-style: italic;
  text-align: center;
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(_x: never): never {
  throw new Error("Didn't expect to get here")
}

const dymStatesOrder: DymState[] = [
  DymState.EXPECTED,
  DymState.ARRIVED,
  DymState.IN_PROCESS,
  DymState.SAP_BOOKED,
  DymState.BOOKED,
  DymState.WITH_PARKING,
]

type SAPBookingState =
  | { type: 'initial' }
  | { type: 'inProgress'; id: string; currentStatus: DymState }
  | { type: 'submitted' }

const isChecked = (currentStatus: DymState, newStatus: DymState): boolean =>
  dymStatesOrder.indexOf(currentStatus) >= dymStatesOrder.indexOf(newStatus)

const isOnTime = (arrived: string, plannedArrival: string) => {
  if (arrived === null || plannedArrival === null) {
    return true
  }

  const diff = -moment(arrived).diff(moment(plannedArrival), 'minutes')

  return diff <= 30
}

const isCheckInProcessDisabled = (state: DymState) =>
  [DymState.BOOKED, DymState.SAP_BOOKED, DymState.WITH_PARKING].includes(state)

const isCheckBookedDisabled = (state: DymState) =>
  [DymState.ARRIVED, DymState.WITH_PARKING].includes(state)
const isCheckSAPBookingDisabled = (state: DymState) => ![DymState.IN_PROCESS].includes(state)

const isCheckWithParkingDisabled = (state: DymState) =>
  [DymState.ARRIVED, DymState.IN_PROCESS].includes(state)

const createPushSapBookingInput = (id: string): PushSapBookingInput => {
  const newBooking: PushSapBookingInput = {
    id,
    dym_status: DymState.SAP_BOOKED,
    dym_timestamp_sap_booked: moment().format(),
  }
  return newBooking
}

const createUpdatedBooking = (
  id: string,
  newStatus: DymState,
  currentStatus: DymState,
): UpdateBookingInput => {
  const newBooking: UpdateBookingInput = { id, dym_status: newStatus }
  switch (currentStatus) {
    case DymState.EXPECTED: {
      break
    }
    case DymState.ARRIVED: {
      newBooking.dym_timestamp_in_process = moment().format()
      break
    }
    case DymState.IN_PROCESS: {
      if (currentStatus === newStatus) {
        newBooking.dym_status = DymState.ARRIVED
      } else {
        newBooking.dym_timestamp_sap_booked = moment().format()
      }
      break
    }
    case DymState.SAP_BOOKED: {
      newBooking.dym_timestamp_booked = moment().format()
      break
    }
    // TO-DO: may neec to dissallow comming back to inprocess
    case DymState.BOOKED: {
      if (currentStatus === newStatus) {
        newBooking.dym_status = DymState.IN_PROCESS
      } else {
        newBooking.dym_timestamp_with_parking = moment().format()
      }
      break
    }
    case DymState.WITH_PARKING: {
      newBooking.dym_status = DymState.BOOKED
      break
    }
    default: {
      assertUnreachable(currentStatus)
    }
  }
  return newBooking
}

type ColumnData = Column<Booking>
const ReceptionistScreen: React.FunctionComponent = () => {
  const [statusFilter, setStatusFilter] = useState<DymState>()
  const { bookingsByArrivalDate, updateBooking, pushBookingToSap } = useBookings()
  const [searchText, setSearchText] = useState('')
  const [showSAPBookingDialog, setShowSAPBookingDialog] = useState<SAPBookingState>({
    type: 'initial',
  })

  const filteredBookings = useMemo(() => filterBookings(bookingsByArrivalDate), [
    bookingsByArrivalDate,
  ])

  useEffect(() => {
    setFilter('license_plate', searchText)
    setFilter('dym_status', statusFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingsByArrivalDate])

  const memoizedColumns: ColumnData[] = useMemo(() => columns, [])

  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    currentStatus: DymState,
    id: string,
  ) => {
    const newBooking: UpdateBookingInput = createUpdatedBooking(
      id,
      event.target.name as DymState,
      currentStatus,
    )
    updateBooking(newBooking)
  }

  const showDialog = (currentStatus: DymState, id: string) => {
    setShowSAPBookingDialog({ type: 'inProgress', currentStatus, id })
  }

  const handleSAPSubmit = () => {
    if (showSAPBookingDialog.type !== 'inProgress') return
    const newBooking = createPushSapBookingInput(showSAPBookingDialog.id)
    pushBookingToSap(newBooking)
    setShowSAPBookingDialog({ type: 'submitted' })
  }

  const closeSAPSubmit = () => {
    setShowSAPBookingDialog({ type: 'initial' })
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore this is there but incorrectly typed
    setFilter,
  } = useTable<Booking>(
    {
      data: filteredBookings,
      columns: memoizedColumns,
    },
    useFilters,
    (hooks) => {
      hooks.visibleColumns.push((visibleColumns) => [
        ...visibleColumns,

        {
          Header: () => <TextAlignCenter>In Bearbeitung</TextAlignCenter>,
          id: 'working',
          Cell: (cell: Cell<Booking> & { data: Booking[] }) => {
            const sapBookedTimestamp =
              cell.data.find((booking) => booking.id === cell.row.values.id)
                ?.dym_timestamp_sap_booked ?? null

            return (
              <CheckboxContainer>
                <input
                  name={DymState.IN_PROCESS}
                  checked={
                    !!sapBookedTimestamp ||
                    isChecked(cell.row.values.dym_status, DymState.IN_PROCESS)
                  }
                  type="checkbox"
                  onChange={(e) => handleSelect(e, cell.row.values.dym_status, cell.row.values.id)}
                  disabled={
                    !!sapBookedTimestamp || isCheckInProcessDisabled(cell.row.values.dym_status)
                  }
                />
              </CheckboxContainer>
            )
          },
        },
        {
          Header: () => <TextAlignCenter>SAP Buchung</TextAlignCenter>,
          id: 'sap_booked',
          Cell: (cell: Cell<Booking> & { data: Booking[] }) => {
            const sapBookedTimestamp =
              cell.data.find((booking) => booking.id === cell.row.values.id)
                ?.dym_timestamp_sap_booked ?? null
            if (sapBookedTimestamp || isChecked(cell.row.values.dym_status, DymState.SAP_BOOKED)) {
              return (
                <CheckboxContainer>
                  <input
                    name={DymState.SAP_BOOKED}
                    type="checkbox"
                    onChange={
                      (e) => handleSelect(e, cell.row.values.dym_status, cell.row.values.id)
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    checked={
                      !!sapBookedTimestamp ||
                      isChecked(cell.row.values.dym_status, DymState.SAP_BOOKED)
                    }
                    disabled={
                      !!sapBookedTimestamp || isCheckBookedDisabled(cell.row.values.dym_status)
                    }
                  />
                </CheckboxContainer>
              )
            }
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            return (
              <CheckboxContainer>
                <button
                  type="button"
                  disabled={isCheckSAPBookingDisabled(cell.row.values.dym_status)}
                  className="btn btn-success"
                  onClick={() => showDialog(cell.row.values.dym_status, cell.row.values.id)}
                >
                  START
                </button>
              </CheckboxContainer>
            )
          },
        },
        {
          Header: () => <TextAlignCenter>Supply Cockpit</TextAlignCenter>,
          id: 'booked',
          Cell: (cell: Cell<Booking>) => (
            <CheckboxContainer>
              <input
                name={DymState.BOOKED}
                type="checkbox"
                onChange={(e) => handleSelect(e, cell.row.values.dym_status, cell.row.values.id)}
                checked={isChecked(cell.row.values.dym_status, DymState.BOOKED)}
                disabled={isCheckBookedDisabled(cell.row.values.dym_status)}
              />
            </CheckboxContainer>
          ),
        },
        {
          Header: () => <TextAlignCenter>Dokumente übergeben</TextAlignCenter>,
          id: 'with_parking',
          Cell: (cell: Cell<Booking>) => (
            <CheckboxContainer>
              <input
                name={DymState.WITH_PARKING}
                type="checkbox"
                onChange={(e) => handleSelect(e, cell.row.values.dym_status, cell.row.values.id)}
                checked={isChecked(cell.row.values.dym_status, DymState.WITH_PARKING)}
                disabled={isCheckWithParkingDisabled(cell.row.values.dym_status)}
              />
            </CheckboxContainer>
          ),
        },
      ])
    },
  )

  const handleFilterChange = (newSearchText: string) => {
    setFilter('license_plate', newSearchText)
    setSearchText(newSearchText)
  }

  const handleStatusChange = (newDymState: DymState | undefined) => {
    setFilter('dym_status', newDymState)
    setStatusFilter(newDymState)
  }

  const headerProps = (
    _props: TableProps,
    meta: MetaBase<Booking> & { column: ColumnData },
    // index: number,
  ): Partial<TableHeaderProps> => ({
    style:
      meta.column.id && hiddenColumns.includes(meta.column.id) ? { display: 'none' } : undefined,
  })

  const cellProps = (
    _props: TableProps,
    meta: MetaBase<Booking> & { cell: Cell<Booking> },
  ): Partial<TableCellProps> => ({
    style:
      meta.cell.column.id && hiddenColumns.includes(meta.cell.column.id)
        ? { display: 'none' }
        : undefined,
  })

  return (
    <StyledContainer className="container-fluid">
      <BookingsFilterBar
        statusFilter={statusFilter}
        searchText={searchText}
        setSearchText={handleFilterChange}
        onChangeStatus={handleStatusChange}
      />
      <div className="table-responsive">
        <table {...getTableProps()} className="table table-bordered table-head-filled table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(headerProps)}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()} dymState={row.values.dym_status}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps(cellProps)}>{cell.render('Cell')}</td>
                  ))}
                </TableRow>
              )
            })}
          </tbody>
        </table>
      </div>
      <Dialog
        show={showSAPBookingDialog.type !== 'initial'}
        title={
          showSAPBookingDialog.type === 'inProgress'
            ? 'Wollen Sie den Status dieser Buchung in SAP ändern?'
            : 'Buchung erfolgreich an SAP System übermittelt'
        }
        body={
          showSAPBookingDialog.type === 'inProgress' ? (
            <p>
              Info: Transport Status wird automatisch in SAP bestätigt (Hacken gesetzt und Status
              geändert “in Yard setzen”). Die Entladeliste wir automatisch ausgedruckt.
            </p>
          ) : (
            <SuccessIconContainer>
              <SuccessIcon className="rioglyph rioglyph-ok" />
            </SuccessIconContainer>
          )
        }
        footer={
          showSAPBookingDialog.type === 'inProgress' ? (
            <button type="button" className="btn btn-success" onClick={() => handleSAPSubmit()}>
              Buchung starten
            </button>
          ) : (
            <HelpText>Ist ein Fehler aufgetreten? Kontaktieren Sie bitte MAN IT Support.</HelpText>
          )
        }
        onHide={() => closeSAPSubmit()}
        showCloseButton
      />
    </StyledContainer>
  )
}

export default ReceptionistScreen
