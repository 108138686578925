import SecurityScreen from './pages/SecurityScreen'
import ReceptionistScreen from './pages/ReceptionistScreen'
import TestScreen from './pages/TestScreen'

type RoutePathType<I extends string> = `/${I}`

type RouteType = { path: RoutePathType<string>; component: React.FC }

export enum AppRoute {
  Receptionist = '/receptionist',
  Test = '/test',
  Security = '/security',
}

export const routes: RouteType[] = [
  { path: AppRoute.Receptionist, component: ReceptionistScreen },
  { path: AppRoute.Security, component: SecurityScreen },
  { path: AppRoute.Test, component: TestScreen },
]
