import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment-timezone'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import TabContainer from '../components/TabContainer'
import SearchField from '../components/SearchField'
import { DymState } from '../API'
import { Booking } from '../types'
import useBookings from '../lib/useBookings'

const doesLicensePlateMatchSearch = (
  licensePlate: string | undefined | null,
  search: string,
): boolean => licensePlate?.toUpperCase()?.includes(search.toUpperCase()) ?? false

const getGroupName = (datetime: string) => {
  const bookingTime = moment(datetime)
  const hour = bookingTime.format('HH')
  const minute = bookingTime.get('minute') >= 30 ? '30' : '00'
  return `${hour}${minute}`
}

const filterStatus = (booking: Booking, state: DymState): boolean => booking.dym_status === state
const filterSearchText = (booking: Booking, searchText?: string): boolean =>
  !searchText || doesLicensePlateMatchSearch(booking.license_plate, searchText)
const filterEmptyLicencePlates = (booking: Booking): boolean =>
  booking.license_plate !== '' &&
  booking.license_plate !== null &&
  booking.license_plate !== 'No Reg Number'

const getUpdatedTimeBlocks = (bookings: Booking[], state: DymState, searchText?: string) =>
  bookings
    .filter((booking) => filterEmptyLicencePlates(booking))
    .filter((booking) => filterStatus(booking, state))
    .filter((booking) => filterSearchText(booking, searchText))
    .reduce((timeBlocks, booking) => {
      const date = booking.planned_date_of_arrival
      if (!date) return timeBlocks
      const groupName = getGroupName(date)

      // eslint-disable-next-line no-param-reassign
      if (!timeBlocks[groupName]) timeBlocks[groupName] = []
      timeBlocks[groupName].push(booking)
      return timeBlocks
    }, {} as Record<string, Booking[]>)

type Tab = {
  id: DymState.EXPECTED | DymState.ARRIVED
  menuItem: string
  tabContent: React.ReactChild
}

const TabWrapper = styled.div`
  &:focus {
    outline: none;
  }
`

const SearchWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #b6bbc3;
  padding-bottom: 1em;
`

const TabLink = styled.a`
  color: #2a3740 !important;
`

const SecurityScreen: React.FunctionComponent = () => {
  const {
    bookingsByArrivalDate,
    networkStatus,
    updateBooking,
    subscribeToMoreBookingsByDate,
  } = useBookings()
  const location = useLocation()
  const [timeBlocks, setTimeBlocks] = useState<Record<string, Booking[]>>({})
  const [currentTab, setCurrentTab] = useState<DymState.EXPECTED | DymState.ARRIVED>(
    DymState.EXPECTED,
  )
  const [searchText, setSearchText] = useState('')

  const onChange = (newSearch: string) => {
    setSearchText(newSearch)
  }

  const tabs: Tab[] = useMemo(
    () => [
      {
        id: DymState.EXPECTED,
        menuItem: 'Erwartet',
        tabContent: (
          <>
            <SearchWrapper className="container form-group">
              <SearchField id="searchtext" onChange={onChange} value={searchText} />
            </SearchWrapper>
            <TabContainer
              tabName={DymState.EXPECTED}
              timeBlocks={timeBlocks}
              updateBooking={updateBooking}
            />
          </>
        ),
      },
      {
        id: DymState.ARRIVED,
        menuItem: 'Eingefahren',
        tabContent: (
          <>
            <SearchWrapper className="container">
              <SearchField id="searchtext" onChange={onChange} value={searchText} />
            </SearchWrapper>
            <TabContainer
              tabName={DymState.ARRIVED}
              timeBlocks={timeBlocks}
              updateBooking={updateBooking}
            />
          </>
        ),
      },
    ],
    [searchText, timeBlocks, updateBooking],
  )

  useEffect(() => {
    const newTab = location.hash.substr(1).toUpperCase()
    if (
      newTab !== '' ||
      tabs.some((tab) => tab.id === (newTab as DymState.EXPECTED | DymState.ARRIVED))
    ) {
      setCurrentTab(location.hash.substr(1).toUpperCase() as DymState.EXPECTED | DymState.ARRIVED)
      setSearchText('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash])

  useEffect(() => {
    const updatedTimeBlocks = getUpdatedTimeBlocks(bookingsByArrivalDate, currentTab, searchText)
    setTimeBlocks(updatedTimeBlocks)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkStatus, currentTab, searchText])

  useEffect(() => {
    const unsubscribe = subscribeToMoreBookingsByDate()

    return () => {
      unsubscribe()
    }
  }, [subscribeToMoreBookingsByDate])

  return (
    <div className="tabs container">
      <ul className="nav nav-tabs" role="tablist">
        {tabs.map((tab) => (
          <li
            key={tab.id}
            role="tab"
            aria-selected="true"
            aria-controls={`${tab.menuItem} tab`}
            className={currentTab === tab.id ? 'active' : undefined}
            id={tab.menuItem}
          >
            <TabLink href={`#${tab.id.toLowerCase()}`}>{tab.menuItem}</TabLink>
          </li>
        ))}
      </ul>
      {tabs.map((tab) => (
        <TabWrapper
          key={tab.menuItem}
          tabIndex={0}
          role="tabpanel"
          id={`${tab.menuItem} tab`}
          aria-labelledby={tab.menuItem}
          hidden={currentTab === tab.id}
        >
          {tab.tabContent}
        </TabWrapper>
      ))}
    </div>
  )
}

export default SecurityScreen
