import { CognitoUser } from '@aws-amplify/auth'
import { createCtx } from '../../utils/createCtx'

import { SignInInput } from './authenticationTypes'

export interface AuthState {
  user: CognitoUser | null
  signIn(input: SignInInput): Promise<void>
  signOut(): Promise<void>
  deleteUser(): Promise<void>
}

export const [useAuthContext, AuthProvider] = createCtx<AuthState>()
