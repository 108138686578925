/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateBookingInput = {
  id?: string | null,
  arrival_date_key: string,
  transport_family?: number | null,
  transport_family_name?: string | null,
  transport_company?: string | null,
  trailer_id: string,
  registration_number?: string | null,
  difference?: string | null,
  planned_date_of_arrival?: string | null,
  comment?: string | null,
  status?: string | null,
  last_modified?: string | null,
  ba_call_first_pearl?: string | null,
  ba_needed_first_pearl?: string | null,
  special_transporttrailer?: boolean | null,
  trailer_calculation?: boolean | null,
  license_plate?: string | null,
  dym_status: DymState,
  dym_timestamp_expected?: string | null,
  dym_timestamp_arrived?: string | null,
  dym_timestamp_in_process?: string | null,
  dym_timestamp_sap_booked?: string | null,
  dym_timestamp_booked?: string | null,
  dym_timestamp_with_parking?: string | null,
};

export enum DymState {
  EXPECTED = "EXPECTED",
  ARRIVED = "ARRIVED",
  IN_PROCESS = "IN_PROCESS",
  SAP_BOOKED = "SAP_BOOKED",
  BOOKED = "BOOKED",
  WITH_PARKING = "WITH_PARKING",
}


export type ModelBookingConditionInput = {
  arrival_date_key?: ModelStringInput | null,
  transport_family?: ModelIntInput | null,
  transport_family_name?: ModelStringInput | null,
  transport_company?: ModelStringInput | null,
  trailer_id?: ModelStringInput | null,
  registration_number?: ModelStringInput | null,
  difference?: ModelStringInput | null,
  planned_date_of_arrival?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  status?: ModelStringInput | null,
  last_modified?: ModelStringInput | null,
  ba_call_first_pearl?: ModelStringInput | null,
  ba_needed_first_pearl?: ModelStringInput | null,
  special_transporttrailer?: ModelBooleanInput | null,
  trailer_calculation?: ModelBooleanInput | null,
  license_plate?: ModelStringInput | null,
  dym_status?: ModelDymStateInput | null,
  dym_timestamp_expected?: ModelStringInput | null,
  dym_timestamp_arrived?: ModelStringInput | null,
  dym_timestamp_in_process?: ModelStringInput | null,
  dym_timestamp_sap_booked?: ModelStringInput | null,
  dym_timestamp_booked?: ModelStringInput | null,
  dym_timestamp_with_parking?: ModelStringInput | null,
  and?: Array< ModelBookingConditionInput | null > | null,
  or?: Array< ModelBookingConditionInput | null > | null,
  not?: ModelBookingConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelDymStateInput = {
  eq?: DymState | null,
  ne?: DymState | null,
};

export type Booking = {
  __typename: "Booking",
  id?: string,
  arrival_date_key?: string,
  transport_family?: number | null,
  transport_family_name?: string | null,
  transport_company?: string | null,
  trailer_id?: string,
  registration_number?: string | null,
  difference?: string | null,
  planned_date_of_arrival?: string | null,
  comment?: string | null,
  status?: string | null,
  last_modified?: string | null,
  ba_call_first_pearl?: string | null,
  ba_needed_first_pearl?: string | null,
  special_transporttrailer?: boolean | null,
  trailer_calculation?: boolean | null,
  license_plate?: string | null,
  dym_status?: DymState,
  dym_timestamp_expected?: string | null,
  dym_timestamp_arrived?: string | null,
  dym_timestamp_in_process?: string | null,
  dym_timestamp_sap_booked?: string | null,
  dym_timestamp_booked?: string | null,
  dym_timestamp_with_parking?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateBookingInput = {
  id: string,
  arrival_date_key?: string | null,
  transport_family?: number | null,
  transport_family_name?: string | null,
  transport_company?: string | null,
  trailer_id?: string | null,
  registration_number?: string | null,
  difference?: string | null,
  planned_date_of_arrival?: string | null,
  comment?: string | null,
  status?: string | null,
  last_modified?: string | null,
  ba_call_first_pearl?: string | null,
  ba_needed_first_pearl?: string | null,
  special_transporttrailer?: boolean | null,
  trailer_calculation?: boolean | null,
  license_plate?: string | null,
  dym_status?: DymState | null,
  dym_timestamp_expected?: string | null,
  dym_timestamp_arrived?: string | null,
  dym_timestamp_in_process?: string | null,
  dym_timestamp_sap_booked?: string | null,
  dym_timestamp_booked?: string | null,
  dym_timestamp_with_parking?: string | null,
};

export type DeleteBookingInput = {
  id: string,
};

export type PushSapBookingInput = {
  id: string,
  dym_status: DymState,
  dym_timestamp_sap_booked: string,
};

export type PushSapBookingOutput = {
  __typename: "PushSapBookingOutput",
  success?: boolean,
  errrors?: Array< string | null > | null,
  data?: PushSAPDataOutput,
};

export type PushSAPDataOutput = {
  __typename: "PushSAPDataOutput",
  id?: string | null,
  arrival_date_key?: string | null,
  trailer_id?: string | null,
  dym_status?: DymState | null,
  dym_timestamp_sap_booked?: string | null,
};

export type ModelBookingFilterInput = {
  id?: ModelIDInput | null,
  arrival_date_key?: ModelStringInput | null,
  transport_family?: ModelIntInput | null,
  transport_family_name?: ModelStringInput | null,
  transport_company?: ModelStringInput | null,
  trailer_id?: ModelStringInput | null,
  registration_number?: ModelStringInput | null,
  difference?: ModelStringInput | null,
  planned_date_of_arrival?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  status?: ModelStringInput | null,
  last_modified?: ModelStringInput | null,
  ba_call_first_pearl?: ModelStringInput | null,
  ba_needed_first_pearl?: ModelStringInput | null,
  special_transporttrailer?: ModelBooleanInput | null,
  trailer_calculation?: ModelBooleanInput | null,
  license_plate?: ModelStringInput | null,
  dym_status?: ModelDymStateInput | null,
  dym_timestamp_expected?: ModelStringInput | null,
  dym_timestamp_arrived?: ModelStringInput | null,
  dym_timestamp_in_process?: ModelStringInput | null,
  dym_timestamp_sap_booked?: ModelStringInput | null,
  dym_timestamp_booked?: ModelStringInput | null,
  dym_timestamp_with_parking?: ModelStringInput | null,
  and?: Array< ModelBookingFilterInput | null > | null,
  or?: Array< ModelBookingFilterInput | null > | null,
  not?: ModelBookingFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBookingConnection = {
  __typename: "ModelBookingConnection",
  items?:  Array<Booking | null > | null,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type CreateBookingMutationVariables = {
  input?: CreateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type CreateBookingMutation = {
  createBooking?:  {
    __typename: "Booking",
    id: string,
    arrival_date_key: string,
    transport_family?: number | null,
    transport_family_name?: string | null,
    transport_company?: string | null,
    trailer_id: string,
    registration_number?: string | null,
    difference?: string | null,
    planned_date_of_arrival?: string | null,
    comment?: string | null,
    status?: string | null,
    last_modified?: string | null,
    ba_call_first_pearl?: string | null,
    ba_needed_first_pearl?: string | null,
    special_transporttrailer?: boolean | null,
    trailer_calculation?: boolean | null,
    license_plate?: string | null,
    dym_status: DymState,
    dym_timestamp_expected?: string | null,
    dym_timestamp_arrived?: string | null,
    dym_timestamp_in_process?: string | null,
    dym_timestamp_sap_booked?: string | null,
    dym_timestamp_booked?: string | null,
    dym_timestamp_with_parking?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBookingMutationVariables = {
  input?: UpdateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type UpdateBookingMutation = {
  updateBooking?:  {
    __typename: "Booking",
    id: string,
    arrival_date_key: string,
    transport_family?: number | null,
    transport_family_name?: string | null,
    transport_company?: string | null,
    trailer_id: string,
    registration_number?: string | null,
    difference?: string | null,
    planned_date_of_arrival?: string | null,
    comment?: string | null,
    status?: string | null,
    last_modified?: string | null,
    ba_call_first_pearl?: string | null,
    ba_needed_first_pearl?: string | null,
    special_transporttrailer?: boolean | null,
    trailer_calculation?: boolean | null,
    license_plate?: string | null,
    dym_status: DymState,
    dym_timestamp_expected?: string | null,
    dym_timestamp_arrived?: string | null,
    dym_timestamp_in_process?: string | null,
    dym_timestamp_sap_booked?: string | null,
    dym_timestamp_booked?: string | null,
    dym_timestamp_with_parking?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBookingMutationVariables = {
  input?: DeleteBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type DeleteBookingMutation = {
  deleteBooking?:  {
    __typename: "Booking",
    id: string,
    arrival_date_key: string,
    transport_family?: number | null,
    transport_family_name?: string | null,
    transport_company?: string | null,
    trailer_id: string,
    registration_number?: string | null,
    difference?: string | null,
    planned_date_of_arrival?: string | null,
    comment?: string | null,
    status?: string | null,
    last_modified?: string | null,
    ba_call_first_pearl?: string | null,
    ba_needed_first_pearl?: string | null,
    special_transporttrailer?: boolean | null,
    trailer_calculation?: boolean | null,
    license_plate?: string | null,
    dym_status: DymState,
    dym_timestamp_expected?: string | null,
    dym_timestamp_arrived?: string | null,
    dym_timestamp_in_process?: string | null,
    dym_timestamp_sap_booked?: string | null,
    dym_timestamp_booked?: string | null,
    dym_timestamp_with_parking?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type PushBookingToSapMutationVariables = {
  pushSapBookingInputData?: PushSapBookingInput | null,
};

export type PushBookingToSapMutation = {
  pushBookingToSAP?:  {
    __typename: "PushSapBookingOutput",
    success: boolean,
    errrors?: Array< string | null > | null,
    data?:  {
      __typename: "PushSAPDataOutput",
      id?: string | null,
      arrival_date_key?: string | null,
      trailer_id?: string | null,
      dym_status?: DymState | null,
      dym_timestamp_sap_booked?: string | null,
    } | null,
  } | null,
};

export type TestPushBookingToSapMutationVariables = {
  pushSapBookingInputData?: string | null,
};

export type TestPushBookingToSapMutation = {
  testPushBookingToSAP?:  {
    __typename: "PushSapBookingOutput",
    success: boolean,
    errrors?: Array< string | null > | null,
    data?:  {
      __typename: "PushSAPDataOutput",
      id?: string | null,
      arrival_date_key?: string | null,
      trailer_id?: string | null,
      dym_status?: DymState | null,
      dym_timestamp_sap_booked?: string | null,
    } | null,
  } | null,
};

export type GetBookingQueryVariables = {
  id?: string,
};

export type GetBookingQuery = {
  getBooking?:  {
    __typename: "Booking",
    id: string,
    arrival_date_key: string,
    transport_family?: number | null,
    transport_family_name?: string | null,
    transport_company?: string | null,
    trailer_id: string,
    registration_number?: string | null,
    difference?: string | null,
    planned_date_of_arrival?: string | null,
    comment?: string | null,
    status?: string | null,
    last_modified?: string | null,
    ba_call_first_pearl?: string | null,
    ba_needed_first_pearl?: string | null,
    special_transporttrailer?: boolean | null,
    trailer_calculation?: boolean | null,
    license_plate?: string | null,
    dym_status: DymState,
    dym_timestamp_expected?: string | null,
    dym_timestamp_arrived?: string | null,
    dym_timestamp_in_process?: string | null,
    dym_timestamp_sap_booked?: string | null,
    dym_timestamp_booked?: string | null,
    dym_timestamp_with_parking?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBookingsQueryVariables = {
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookingsQuery = {
  listBookings?:  {
    __typename: "ModelBookingConnection",
    items?:  Array< {
      __typename: "Booking",
      id: string,
      arrival_date_key: string,
      transport_family?: number | null,
      transport_family_name?: string | null,
      transport_company?: string | null,
      trailer_id: string,
      registration_number?: string | null,
      difference?: string | null,
      planned_date_of_arrival?: string | null,
      comment?: string | null,
      status?: string | null,
      last_modified?: string | null,
      ba_call_first_pearl?: string | null,
      ba_needed_first_pearl?: string | null,
      special_transporttrailer?: boolean | null,
      trailer_calculation?: boolean | null,
      license_plate?: string | null,
      dym_status: DymState,
      dym_timestamp_expected?: string | null,
      dym_timestamp_arrived?: string | null,
      dym_timestamp_in_process?: string | null,
      dym_timestamp_sap_booked?: string | null,
      dym_timestamp_booked?: string | null,
      dym_timestamp_with_parking?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type BookingsByArrivalDateQueryVariables = {
  arrival_date_key?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingsByArrivalDateQuery = {
  bookingsByArrivalDate?:  {
    __typename: "ModelBookingConnection",
    items?:  Array< {
      __typename: "Booking",
      id: string,
      arrival_date_key: string,
      transport_family?: number | null,
      transport_family_name?: string | null,
      transport_company?: string | null,
      trailer_id: string,
      registration_number?: string | null,
      difference?: string | null,
      planned_date_of_arrival?: string | null,
      comment?: string | null,
      status?: string | null,
      last_modified?: string | null,
      ba_call_first_pearl?: string | null,
      ba_needed_first_pearl?: string | null,
      special_transporttrailer?: boolean | null,
      trailer_calculation?: boolean | null,
      license_plate?: string | null,
      dym_status: DymState,
      dym_timestamp_expected?: string | null,
      dym_timestamp_arrived?: string | null,
      dym_timestamp_in_process?: string | null,
      dym_timestamp_sap_booked?: string | null,
      dym_timestamp_booked?: string | null,
      dym_timestamp_with_parking?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type BookingsByTrailerIdQueryVariables = {
  trailer_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingsByTrailerIdQuery = {
  bookingsByTrailerID?:  {
    __typename: "ModelBookingConnection",
    items?:  Array< {
      __typename: "Booking",
      id: string,
      arrival_date_key: string,
      transport_family?: number | null,
      transport_family_name?: string | null,
      transport_company?: string | null,
      trailer_id: string,
      registration_number?: string | null,
      difference?: string | null,
      planned_date_of_arrival?: string | null,
      comment?: string | null,
      status?: string | null,
      last_modified?: string | null,
      ba_call_first_pearl?: string | null,
      ba_needed_first_pearl?: string | null,
      special_transporttrailer?: boolean | null,
      trailer_calculation?: boolean | null,
      license_plate?: string | null,
      dym_status: DymState,
      dym_timestamp_expected?: string | null,
      dym_timestamp_arrived?: string | null,
      dym_timestamp_in_process?: string | null,
      dym_timestamp_sap_booked?: string | null,
      dym_timestamp_booked?: string | null,
      dym_timestamp_with_parking?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnCreateBookingSubscription = {
  onCreateBooking?:  {
    __typename: "Booking",
    id: string,
    arrival_date_key: string,
    transport_family?: number | null,
    transport_family_name?: string | null,
    transport_company?: string | null,
    trailer_id: string,
    registration_number?: string | null,
    difference?: string | null,
    planned_date_of_arrival?: string | null,
    comment?: string | null,
    status?: string | null,
    last_modified?: string | null,
    ba_call_first_pearl?: string | null,
    ba_needed_first_pearl?: string | null,
    special_transporttrailer?: boolean | null,
    trailer_calculation?: boolean | null,
    license_plate?: string | null,
    dym_status: DymState,
    dym_timestamp_expected?: string | null,
    dym_timestamp_arrived?: string | null,
    dym_timestamp_in_process?: string | null,
    dym_timestamp_sap_booked?: string | null,
    dym_timestamp_booked?: string | null,
    dym_timestamp_with_parking?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBookingSubscription = {
  onUpdateBooking?:  {
    __typename: "Booking",
    id: string,
    arrival_date_key: string,
    transport_family?: number | null,
    transport_family_name?: string | null,
    transport_company?: string | null,
    trailer_id: string,
    registration_number?: string | null,
    difference?: string | null,
    planned_date_of_arrival?: string | null,
    comment?: string | null,
    status?: string | null,
    last_modified?: string | null,
    ba_call_first_pearl?: string | null,
    ba_needed_first_pearl?: string | null,
    special_transporttrailer?: boolean | null,
    trailer_calculation?: boolean | null,
    license_plate?: string | null,
    dym_status: DymState,
    dym_timestamp_expected?: string | null,
    dym_timestamp_arrived?: string | null,
    dym_timestamp_in_process?: string | null,
    dym_timestamp_sap_booked?: string | null,
    dym_timestamp_booked?: string | null,
    dym_timestamp_with_parking?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBookingSubscription = {
  onDeleteBooking?:  {
    __typename: "Booking",
    id: string,
    arrival_date_key: string,
    transport_family?: number | null,
    transport_family_name?: string | null,
    transport_company?: string | null,
    trailer_id: string,
    registration_number?: string | null,
    difference?: string | null,
    planned_date_of_arrival?: string | null,
    comment?: string | null,
    status?: string | null,
    last_modified?: string | null,
    ba_call_first_pearl?: string | null,
    ba_needed_first_pearl?: string | null,
    special_transporttrailer?: boolean | null,
    trailer_calculation?: boolean | null,
    license_plate?: string | null,
    dym_status: DymState,
    dym_timestamp_expected?: string | null,
    dym_timestamp_arrived?: string | null,
    dym_timestamp_in_process?: string | null,
    dym_timestamp_sap_booked?: string | null,
    dym_timestamp_booked?: string | null,
    dym_timestamp_with_parking?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
