/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBooking = /* GraphQL */ `
  subscription OnCreateBooking {
    onCreateBooking {
      id
      arrival_date_key
      transport_family
      transport_family_name
      transport_company
      trailer_id
      registration_number
      difference
      planned_date_of_arrival
      comment
      status
      last_modified
      ba_call_first_pearl
      ba_needed_first_pearl
      special_transporttrailer
      trailer_calculation
      license_plate
      dym_status
      dym_timestamp_expected
      dym_timestamp_arrived
      dym_timestamp_in_process
      dym_timestamp_sap_booked
      dym_timestamp_booked
      dym_timestamp_with_parking
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBooking = /* GraphQL */ `
  subscription OnUpdateBooking {
    onUpdateBooking {
      id
      arrival_date_key
      transport_family
      transport_family_name
      transport_company
      trailer_id
      registration_number
      difference
      planned_date_of_arrival
      comment
      status
      last_modified
      ba_call_first_pearl
      ba_needed_first_pearl
      special_transporttrailer
      trailer_calculation
      license_plate
      dym_status
      dym_timestamp_expected
      dym_timestamp_arrived
      dym_timestamp_in_process
      dym_timestamp_sap_booked
      dym_timestamp_booked
      dym_timestamp_with_parking
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBooking = /* GraphQL */ `
  subscription OnDeleteBooking {
    onDeleteBooking {
      id
      arrival_date_key
      transport_family
      transport_family_name
      transport_company
      trailer_id
      registration_number
      difference
      planned_date_of_arrival
      comment
      status
      last_modified
      ba_call_first_pearl
      ba_needed_first_pearl
      special_transporttrailer
      trailer_calculation
      license_plate
      dym_status
      dym_timestamp_expected
      dym_timestamp_arrived
      dym_timestamp_in_process
      dym_timestamp_sap_booked
      dym_timestamp_booked
      dym_timestamp_with_parking
      createdAt
      updatedAt
    }
  }
`;
