import React from 'react'
import styled, { css } from 'styled-components'
import { DymState } from '../API'
import Spacer from './Spacer'

const FilterBarContainer = styled.div`
  height: 60px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 0 100px;

  @media screen and (max-width: 992px) {
    margin: 0 10px;
  }
  @media screen and (max-width: 672px) {
    margin: 0;
  }
`

const Input = styled.input``

const SearchInputGroup = styled.div`
  display: flex;
  flex: 1;
`

type Props = {
  statusFilter: DymState | undefined
  onChangeStatus: (status: DymState | undefined) => void
  searchText: string
  setSearchText: (searchText: string) => void
}

const dymStates: DymState[] = [
  DymState.ARRIVED,
  DymState.IN_PROCESS,
  DymState.BOOKED,
  DymState.WITH_PARKING,
]

const stateToText: Record<DymState, string> = {
  EXPECTED: '',
  ARRIVED: 'Offen',
  IN_PROCESS: 'In Bearbeitung',
  SAP_BOOKED: 'SAP Buchung',
  BOOKED: 'Gebucht',
  WITH_PARKING: 'Übergabe',
}

const stateToColor: Record<DymState, string> = {
  EXPECTED: 'inherit',
  ARRIVED: '#F2BF43',
  IN_PROCESS: '#4085CA',
  SAP_BOOKED: '#4085CA',
  BOOKED: '#59B755',
  WITH_PARKING: '#6C7989',
}

const AllButton = styled.button<{ active: boolean }>`
  color: #6c7989 !important;
  background: #ffffff !important;
  border: 1px solid #d1d8dd !important;

  ${(p) =>
    p.active &&
    css`
      background: #f5f5f5 !important;
      border: 1px solid #d1d8dd !important;
    `}
`

const Button = styled.button<{ state: DymState; active: boolean }>`
  background-color: white !important;
  color: ${(p) => stateToColor[p.state]} !important;
  border-color: ${(p) => stateToColor[p.state]} !important;

  ${(p) =>
    p.active &&
    css`
      background-color: ${stateToColor[p.state]} !important;
      color: white !important;
    `}

  &:hover {
    background-color: ${(p) => (p.active ? 'transparent' : stateToColor[p.state])};
  }
`

const BookingsFilterBar: React.FC<Props> = React.memo(
  ({ statusFilter, onChangeStatus, searchText, setSearchText }) => {
    const isActive = (state: DymState): boolean => statusFilter === state

    return (
      <FilterBarContainer className="">
        <div className="btn-toolbar">
          <AllButton
            type="button"
            active={statusFilter === undefined}
            className={`btn btn-default btn-muted ${
              statusFilter === undefined ? 'btn-disabled' : 'btn-outline'
            }`}
            onClick={() => onChangeStatus(undefined)}
          >
            <span>Alle</span>
          </AllButton>
          {dymStates.map((state) => (
            <Button
              key={state}
              type="button"
              state={state}
              active={isActive(state)}
              className={`btn btn-primary btn-icon-right ${
                isActive(state) ? 'btn-disabled' : 'btn-outline'
              }`}
              onClick={() => onChangeStatus(state)}
            >
              <span>{stateToText[state]}</span>
            </Button>
          ))}
        </div>
        <Spacer axis="horizontal" size={20} when={{ mdAndUp: 60, lgAndUp: 200, xlAndUp: 640 }} />
        <SearchInputGroup className="input-group">
          <span className="input-group-addon">
            <span className="rioglyph rioglyph-search" aria-hidden="true" />
          </span>
          <Input
            placeholder="Suchen..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="form-control"
          />
        </SearchInputGroup>
      </FilterBarContainer>
    )
  },
)

export default BookingsFilterBar
