import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'
import { ApolloProvider } from '@apollo/client'
import { Auth } from '@aws-amplify/auth'
import awsconfig from './aws-exports'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { client } from './lib/apolloClient'

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
