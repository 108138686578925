import React from 'react'
import moment from 'moment-timezone'
import styled from 'styled-components'
import { UpdateBookingInput } from '../API'
import { Booking } from '../types'
import SecurityBookingItem from './SecurityBookingItem'

type Props = {
  tabName: string
  timeBlocks: Record<string, Booking[]>
  updateBooking: (bookingInput: UpdateBookingInput) => void
}

const sortTimeBlocks = (timeA: string, timeB: string): -1 | 0 | 1 => {
  if (timeA === timeB) return 0
  return moment(timeA, 'HH:mm').isBefore(moment(timeB, 'HH:mm')) ? -1 : 1
}

const Title = styled.h3`
  margin: 1em 0;
`

const Container = styled.div`
  padding-bottom: 2em;
`

const TabContainer: React.FC<Props> = React.memo(({ tabName, timeBlocks, updateBooking }) => (
  <Container className="container" id={tabName}>
    <div className="divider" />
    <div data-tab-container>
      {Object.entries(timeBlocks)
        .sort(([timeA], [timeB]) => sortTimeBlocks(timeA, timeB))
        .map(([timeBlock, bookings]) =>
          bookings.length > 0 ? (
            <div key={timeBlock} className="container">
              <div className="row">
                <Title>{moment(timeBlock, 'HH:mm').format('HH:mm')}</Title>
              </div>
              <div className="row">
                {bookings.map((booking) => (
                  <SecurityBookingItem
                    key={booking.id}
                    booking={booking}
                    updateBooking={updateBooking}
                  />
                ))}
              </div>
            </div>
          ) : null,
        )}
    </div>
  </Container>
))

export default TabContainer
