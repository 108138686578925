import React from 'react'
import styled from 'styled-components'

type Props = {
  onChange: (value: string) => void
  value: string
  id: string
}

const InputWrapper = styled.div`
  flex: 1 0 auto;
`

const StyledInput = styled.input`
  padding-left: 30px;
`

const Label = styled.label`
  margin-right: 1em;
`

const FormGroup = styled.div``

function SearchField({ onChange, value, id }: Props): JSX.Element {
  return (
    <div className="form-inline">
      <FormGroup className="form-group">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <Label htmlFor={id}>Suchen</Label>
        <div className="input-group">
          <span className="input-group-addon">
            <span className="rioglyph rioglyph-search" aria-hidden="true" />
          </span>
          <InputWrapper className="input-group">
            <StyledInput
              id={id}
              name={id}
              className="form-control"
              data-search-component
              // placeholder="Suchen..."
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </InputWrapper>
        </div>
      </FormGroup>
    </div>
  )
}

export default React.memo(SearchField)
