// https://www.joshwcomeau.com/react/modern-spacer-gif/
import styled, { css } from 'styled-components'

type Axis = 'horizontal' | 'vertical'

type Sizes = 'sm' | 'md' | 'lg' | 'xl'

type MediaQuerySize = `${Sizes}AndUp`

export type SpacerProps = {
  axis: Axis
  size: number
  when?: Partial<Record<MediaQuerySize, number>>
}

function getHeight({ axis, size }: SpacerProps) {
  return axis === 'horizontal' ? 1 : size
}

function getWidth({ axis, size }: SpacerProps) {
  return axis === 'vertical' ? 1 : size
}

const Spacer = styled.span<SpacerProps>`
  display: block;
  width: ${getWidth}px;
  min-width: ${getWidth}px;
  height: ${getHeight}px;
  min-height: ${getHeight}px;

  ${(p) =>
    p.when?.smAndUp &&
    css`
      @media screen and (min-width: 768px) {
        width: ${getWidth({ axis: p.axis, size: p.when?.smAndUp })}px;
        min-width: ${getWidth({ axis: p.axis, size: p.when?.smAndUp })}px;
        height: ${getHeight({ axis: p.axis, size: p.when?.smAndUp })}px;
        min-height: ${getHeight({ axis: p.axis, size: p.when?.smAndUp })}px;
      }
    `}
  ${(p) =>
    p.when?.mdAndUp &&
    css`
      @media screen and (min-width: 992px) {
        width: ${getWidth({ axis: p.axis, size: p.when?.mdAndUp })}px;
        min-width: ${getWidth({ axis: p.axis, size: p.when?.mdAndUp })}px;
        height: ${getHeight({ axis: p.axis, size: p.when?.mdAndUp })}px;
        min-height: ${getHeight({ axis: p.axis, size: p.when?.mdAndUp })}px;
      }
    `}
  ${(p) =>
    p.when?.lgAndUp &&
    css`
      @media screen and (min-width: 1200px) {
        width: ${getWidth({ axis: p.axis, size: p.when?.lgAndUp })}px;
        min-width: ${getWidth({ axis: p.axis, size: p.when?.lgAndUp })}px;
        height: ${getHeight({ axis: p.axis, size: p.when?.lgAndUp })}px;
        min-height: ${getHeight({ axis: p.axis, size: p.when?.lgAndUp })}px;
      }
    `}
  ${(p) =>
    p.when?.xlAndUp &&
    css`
      @media screen and (min-width: 1700px) {
        width: ${getWidth({ axis: p.axis, size: p.when?.xlAndUp })}px;
        min-width: ${getWidth({ axis: p.axis, size: p.when?.xlAndUp })}px;
        height: ${getHeight({ axis: p.axis, size: p.when?.xlAndUp })}px;
        min-height: ${getHeight({ axis: p.axis, size: p.when?.xlAndUp })}px;
      }
    `}
`
export default Spacer

export const InlineSpacer = styled(Spacer)`
  display: inline-block;
`
