import { useCallback, useEffect, useState } from 'react'
import Auth, { CognitoUser } from '@aws-amplify/auth'

import {
  SignInInput,
  SignUpInput,
  ConfirmSignUpInput,
  ResendSignUpInput,
  ForgotPasswordInput,
  ResetPasswordInput,
} from './authenticationTypes'
import { useAuthContext, AuthState } from './authenticationContext'

export function useAuth(): AuthState {
  const [user, setUser] = useState<CognitoUser | null>(null)

  useEffect(() => {
    let active = true

    const check = async () => {
      try {
        const newUser = await Auth.currentAuthenticatedUser()
        if (active) setUser(newUser)
      } catch (error) {
        if (active) setUser(null)
      }
    }

    check()

    return () => {
      active = false
    }
  }, [setUser])

  const signIn = useCallback(
    async ({ email, password }: SignInInput) => {
      setUser(await Auth.signIn(email, password))
    },
    [setUser],
  )

  const signOut = useCallback(async () => {
    await Auth.signOut()
    setUser(null)
  }, [setUser])

  const deleteUser = useCallback(async () => {
    user?.deleteUser((error?: Error) => {
      if (error) throw error

      setUser(null)
    })
  }, [user, setUser])

  return { user, signIn, signOut, deleteUser }
}

/*
 * Custom attributes type defined according to the attributes used in this app
 */
export interface UserAttributes {
  sub: string
  email: string
  email_verified: string
  name: string
  updated_at: string
  'custom:bytesQuota': string
  'custom:bytesUsed': string
}

/*
 * The following interface extends the CognitoUser type because it has issues
 * (see github.com/aws-amplify/amplify-js/issues/4927). Eventually (when you
 * no longer get an error accessing a CognitoUser's 'attribute' property) you
 * will be able to use the CognitoUser type instead of CognitoUserExt.
 */
interface CognitoUserExt extends CognitoUser {
  attributes: UserAttributes
}

export function useUser(): CognitoUserExt | null {
  const { user } = useAuthContext()
  if (!user) return null

  // @ts-ignore: See https://github.com/aws-amplify/amplify-js/issues/4927
  return user.attributes
}

export function useSignIn(): AuthState['signIn'] {
  return useAuthContext().signIn
}

export function useSignOut(): AuthState['signOut'] {
  return useAuthContext().signOut
}

async function signUp({ name, email, password }: SignUpInput) {
  await Auth.signUp({ username: email, password, attributes: { name, email } })
}
export function useSignUp(): typeof signUp {
  return signUp
}

async function confirmSignUp({ email, code }: ConfirmSignUpInput) {
  await Auth.confirmSignUp(email, code)
}

export function useConfirmSignUp(): typeof confirmSignUp {
  return confirmSignUp
}
async function resendSignUp({ email }: ResendSignUpInput) {
  await Auth.resendSignUp(email)
}
export function useResendSignUp(): typeof resendSignUp {
  return resendSignUp
}
async function forgotPassword({ email }: ForgotPasswordInput) {
  await Auth.forgotPassword(email)
}
export function useForgotPassword(): typeof forgotPassword {
  return forgotPassword
}

async function resetPassword({ email, code, password }: ResetPasswordInput) {
  await Auth.forgotPasswordSubmit(email, code, password)
}
export function useResetPassword(): typeof resetPassword {
  return resetPassword
}

export function useDeleteUser(): AuthState['deleteUser'] {
  return useAuthContext().deleteUser
}
